<template>
  <div class="flex justify-center items-center flex-col select-none">
    <div class="main-icon flex justify-center items-center mb-6 mt-12 text-9xl">
      <img :src="getImage()" alt="not found" />
    </div>
    <h1 class="text-2xl mb-12 text-gray-500">{{ message }}</h1>
  </div>
</template>

<script>
import { getSystemFilesURL } from "@/helpers";
import Vue from "vue";
export default Vue.extend({
  props: {
    message: {
      type: String,
      default: "No Results Found"
    },
    image: {
      type: String,
      default: getSystemFilesURL("not-found-default")
    }
  },
  methods: {
    getImage() {
      return this.image;
    }
  }
});
</script>
