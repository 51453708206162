import * as dateFns from "date-fns";

export const tableColumnListAdjusterFiles = {
  Key: {
    formatDataKey: "Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  Size: {
    formatDataKey: "Size",
    formatDataValue: (arg: number) => `${Math.round(arg / 1000)} KB`,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1
  },
  LastModified: {
    formatDataKey: "Last Modified",
    formatDataValue: (arg: string) =>
      dateFns.format(new Date(arg), "LLLL do, yyyy"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 2
  }
};
