
import Vue from "vue";
import { tableColumnListAdjusterFiles } from "./AdjusterTable";
import { ITableWrapperColumns } from "@/types";
import {
  adjusterfileMapActions,
  adjusterfileMapGetters,
  adjusterfileMapState,
  adjusterfileMapMutations
} from "@/store/modules/adjusterfile";
// import * as selectOptions from "@/helpers/selectOptions";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { get } from "lodash";

interface IAdjusterData {
  tableActions: any[];
  tableColumns: ITableWrapperColumns;
  errorMessage: string;
  isLoading: boolean;
  loading: boolean;
  successMessage: string;
  adjusterfileId: string;
  filters: any[];
  loadingText: string;
}
export default Vue.extend({
  name: "ListAdjusterReportTable",
  components: {
    NoResultsFound
  },
  props: {
    queryOverride: {
      type: Object,
      required: false,
      default: () => ({})
    },
    emitOnAction: {
      type: Boolean,
      default: false
    },
    additionalTableActions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data(): IAdjusterData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      tableActions: [...(this.additionalTableActions as any)],
      tableColumns: tableColumnListAdjusterFiles,
      loading: false,
      adjusterfileId: "",
      filters: [
        // {
        //   field: "status",
        //   title: "Status",
        //   options: selectOptions.AdjusterShowOptions,
        //   maxValues: 1
        // }
      ],
      loadingText: ""
    };
  },
  methods: {
    ...adjusterfileMapActions([
      "getAdjusterfiles",
      "deleteAdjusterfile",
      "exportAdjusterFile"
    ]),
    ...adjusterfileMapMutations(["SET_STATES"]),
    tableActionHandler(action: string) {
      switch (action) {
        default:
          if (this.emitOnAction) {
            return this.$emit("tableAction", action);
          }
          break;
      }
    },
    async fetchPageData(queryObject: Record<string, any>): Promise<any> {
      try {
        this.loadingText = "Loading adjuster reports. Please wait...";
        this.$emit("scrollTop");
        if (queryObject) {
          let adjusterCode = queryObject.__query;
          delete queryObject.__query;
          if (this.$getCurrentUser.role === "adjuster") {
            adjusterCode = this.adjusterCode;
          }

          queryObject.adjusterCode = adjusterCode
            ? adjusterCode.toUpperCase()
            : adjusterCode;
        }
        await this.getAdjusterfiles({
          query: { ...queryObject, ...this.queryOverride }
        });
      } catch (error) {
        const errorMessage = `Error fetching Adjuster files. Try again later`;
        this.$emit("setErrorMessage", errorMessage);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loadingText = "";
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      const { Key: fileName } = dataReceived;
      this.exportAdjusterFile({ adjusterCode: this.adjusterCode, fileName });
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "edit":
          this.$router
            .push(`/admin/adjusterfile/${data._id}/edit`)
            .catch(() => {});
          break;
        case "view":
          this.$router.push(`/admin/adjusterfile/${data._id}`).catch(() => {});
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteOneAdjuster(): Promise<void> {
      try {
        this.loadingText = "Deleting adjuster. Please wait...";
        await this.deleteAdjusterfile(this.adjusterfileId);
        this.$modal.hide("adjusterPortalModal");
        this.$notify.success("Adjuster Successfully Deleted");
        this.$router.replace("/admin/adjusterfile").catch(() => {});
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loadingText = "";
      }
    },
    async deleteCallback(adjusterfileId: any): Promise<void> {
      try {
        this.adjusterfileId = adjusterfileId;
        this.$modal.show("adjusterPortalModal");
      } catch (error) {
        if (error !== "cancel") {
          console.error(error);
        }
        this.$bugSnagClient.notify(error);
      }
    }
  },
  destroyed() {
    this.SET_STATES({ adjusterfiles: [] });
  },
  created() {
    this.SET_STATES({ adjusterfiles: [] });
  },
  computed: {
    ...adjusterfileMapGetters([
      "adjusterfiles",
      "getTotalRecords",
      "getAdjusterLinks"
    ]),
    ...adjusterfileMapState(["makingApiRequest"]),
    isAdmin(): boolean {
      const role = get(this.$getCurrentUser, "role", "");
      return role === "admin";
    },
    adjusterCode(): string {
      return get(this.$getCurrentUser, "adjusterCode", "");
    }
  }
});
